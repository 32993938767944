import React from 'react'

import { Body } from 'ethos-design-system'

import salamanderCheckmark from '../../../img/checkmark-icon.svg'
import xIcon from '../../../img/x-icon.svg'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../global/Markdown'
import { ComparisonChartYesNoModuleData } from '../ComparisonChartYesNo'
import styles from './Original.module.scss'

export const Original = ({
  moduleData,
}: {
  moduleData: ComparisonChartYesNoModuleData
}): JSX.Element => {
  const { groups, title1, title2 } = moduleData

  const checkmark = (
    <div className={styles.icon}>
      <img
        src={salamanderCheckmark.src}
        alt="Ethos checkmark"
        style={{ width: '17px' }}
      />
    </div>
  )

  const xIconMarkup = (
    <div className={styles.icon}>
      <img src={xIcon.src} alt="Ethos X icon" style={{ width: '14px' }} />
    </div>
  )

  return (
    <div className={styles.chart}>
      <div className={[styles.row, styles.desktop].join(' ')}>
        <div className={styles.column}>&nbsp;</div>
        <div className={styles.column}>
          <Body.Regular400>{title1}</Body.Regular400>
        </div>
        <div className={styles.column}>
          <Body.Regular400>{title2}</Body.Regular400>
        </div>
      </div>
      {groups.map((row, idx) => (
        <div className={styles.row} key={idx}>
          <div className={styles.column}>
            <Body.Regular400>
              <Markdown
                input={row.heading}
                allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
              />
            </Body.Regular400>
          </div>
          <div className={styles.column}>
            <div className={styles.mobile}>
              <Body.Regular400>{title1}</Body.Regular400>
            </div>
            {row.yesNo1 === 'yes' ? checkmark : xIconMarkup}
          </div>
          <div className={styles.column}>
            <div className={styles.mobile}>
              <Body.Regular400>{title2}</Body.Regular400>
            </div>
            {row.yesNo2 === 'yes' ? checkmark : xIconMarkup}
          </div>
        </div>
      ))}
    </div>
  )
}
