import React from 'react'

import classNames from 'classnames'
import { Body, Body2, COLORS, Caption2 } from 'ethos-design-system'

import { useTrustageLandingPage } from '@/hooks/features/useTrustageLandingPage'

import cypressCheckmark from '../../../img/cypress-checkmark-icon.svg'
import agentsLogo from '../../../img/ethos-logo-forest-for-agents.svg'
import consumerLogo from '../../../img/ethos-logo-forest.svg'
import forestCheckmark from '../../../img/forest-checkmark-icon.svg'
import grayCheckmark from '../../../img/gray-checkmark-icon.svg'
import xIcon from '../../../img/x-icon.svg'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../global/Markdown'
import {
  ComparisonChartYesNoLayout,
  ComparisonChartYesNoModuleData,
} from '../ComparisonChartYesNo'
import styles from './Branded.module.scss'

const circleXIcon =
  'https://res.cloudinary.com/getethos/image/upload/v1685725216/02_Icons/transparent_gray_x_icon_whssmb.png'
const circleCheckmark =
  'https://res.cloudinary.com/getethos/image/upload/v1685725216/02_Icons/white_and_green_checkmark_icon_hhcuk8.png'
const circleXIconRed =
  'https://res.cloudinary.com/getethos/image/upload/v1724224621/circle_x_red_vvrfxw.svg'

const getTrustageExperimentGroups = (): Pick<
  ComparisonChartYesNoModuleData,
  'groups'
> => {
  const groups: Pick<ComparisonChartYesNoModuleData, 'groups'> = {
    groups: [
      {
        heading: 'Instant approval',
        yesNo1: 'yes',
        yesNo2: 'no',
      },
      {
        heading: 'No medical exams',
        yesNo1: 'yes',
        yesNo2: 'no',
      },
      {
        heading: 'Coverage begins',
        yesNo1: 'Instant, same-day',
        yesNo2: 'In 2-6 weeks',
      },
      {
        heading: 'Legal will & trust',
        yesNo1: 'Free',
        yesNo2: 'Additional $1,000+',
      },
      {
        heading: 'Trusted carriers',
        yesNo1: 'yes',
        yesNo2: 'yes',
      },
    ],
  }
  return groups
}

export const Branded = ({
  moduleData,
}: {
  moduleData: ComparisonChartYesNoModuleData
}) => {
  const logo = moduleData.ethosLogo === 'forAgents' ? agentsLogo : consumerLogo
  const { title2, groups } = moduleData
  const { isTreatment } = useTrustageLandingPage()

  let checkmarkSrc = forestCheckmark.src,
    xIconSrc = xIcon.src,
    rebranded = false,
    augmentedChartStyle = '',
    checkmarkWidth = '17px',
    xIconWidth = '14px',
    DesktopTextFormat = Body.Regular400,
    MobileTextFormat = Body2.Regular400,
    MobileHeadingFormat = Caption2.Regular400,
    MobileHeadingColor = COLORS.GRAY_SECONDARY
  switch (moduleData.layout) {
    case ComparisonChartYesNoLayout.Rebranded:
      checkmarkSrc = cypressCheckmark.src
      augmentedChartStyle = styles.rebranded
      rebranded = true
      break
    case ComparisonChartYesNoLayout.Northstar:
      checkmarkSrc = circleCheckmark
      xIconSrc = circleXIcon
      checkmarkWidth = '32px'
      xIconWidth = checkmarkWidth
      DesktopTextFormat = Body.Medium500
      MobileTextFormat = Caption2.Medium500
      MobileHeadingFormat = Caption2.Medium500
      MobileHeadingColor = ''
      augmentedChartStyle = styles.northstar
      break
    case ComparisonChartYesNoLayout.Wills:
      checkmarkSrc = circleCheckmark
      xIconSrc = circleXIconRed
      checkmarkWidth = '40px'
      xIconWidth = '40px'
      DesktopTextFormat = Body.Medium500
      MobileTextFormat = Caption2.Medium500
      MobileHeadingFormat = Caption2.Medium500
      MobileHeadingColor = ''
      augmentedChartStyle = styles.wills
      break
  }

  const checkmark = (
    <div className={styles.icon}>
      <img
        src={checkmarkSrc}
        alt="Green Ethos checkmark"
        style={{ width: checkmarkWidth }}
        className={styles.checkmark}
      />
    </div>
  )

  const grayCheckmarkMarkup = (
    <div className={styles.icon}>
      <img
        src={grayCheckmark.src}
        alt="Gray Ethos checkmark"
        style={{ width: checkmarkWidth }}
      />
    </div>
  )

  const xIconMarkup = (
    <div className={styles.icon}>
      <img
        src={xIconSrc}
        alt="Ethos X icon"
        style={{ width: xIconWidth }}
        className={styles.xIcon}
      />
    </div>
  )

  const groupsToUse = isTreatment
    ? getTrustageExperimentGroups().groups
    : groups

  return (
    <div className={[styles.chart, augmentedChartStyle].join(' ')}>
      <div className={styles.row}>
        <div className={styles.column}>&nbsp;</div>
        <div className={styles.column}>
          <img
            src={logo.src}
            alt="Ethos Logo"
            className={classNames(
              styles.logo,
              moduleData.ethosLogo === 'forAgents' && 'px-3'
            )}
          />
        </div>
        <div className={styles.column}>
          <div className={styles.mobile}>
            <MobileHeadingFormat color={MobileHeadingColor}>
              {title2}
            </MobileHeadingFormat>
          </div>
          <div className={styles.desktop}>
            <DesktopTextFormat>{title2}</DesktopTextFormat>
          </div>
        </div>
      </div>
      {groupsToUse.map((row, idx) => (
        <div className={styles.row} key={idx}>
          <div className={styles.column}>
            <div className={styles.mobile}>
              <MobileTextFormat>
                <Markdown
                  input={row.heading}
                  allowedMarkdownTypes={
                    MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY
                  }
                />
              </MobileTextFormat>
            </div>
            <div className={styles.desktop}>
              <DesktopTextFormat>
                <Markdown
                  input={row.heading}
                  allowedMarkdownTypes={
                    MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY
                  }
                />
              </DesktopTextFormat>
            </div>
          </div>
          <div className={styles.column}>
            {row.yesNo1 === 'yes' ? (
              checkmark
            ) : row.yesNo1 === 'no' ? (
              xIconMarkup
            ) : (
              <div className={classNames(styles.text, styles.ethos)}>
                {row.yesNo1}
              </div>
            )}
          </div>
          <div className={styles.column}>
            {row.yesNo2 === 'yes' ? (
              rebranded ? (
                grayCheckmarkMarkup
              ) : (
                checkmark
              )
            ) : row.yesNo2 === 'no' ? (
              xIconMarkup
            ) : (
              <div className={classNames(styles.text, styles.traditional)}>
                {row.yesNo2}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
