import React from 'react'

import classNames from 'classnames/bind'
import {
  Body,
  Button,
  COLORS,
  Footnote,
  Spacer,
  TitleLarge,
} from 'ethos-design-system'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'

import { useTrustageLandingPage } from '@/hooks/features/useTrustageLandingPage'
import { useNavigateToApp } from '@/hooks/useNavigateToApp'

import northstarStyles from '@/styles/northstar.module.scss'

import { MODULES } from '../../../lib/@getethos/analytics/constants'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../global/Markdown'
import styles from './ComparisonChartYesNo.module.scss'
import { Branded } from './charts/Branded'
import { Original } from './charts/Original'

export enum ComparisonChartYesNoLayout {
  Original = 'original',
  Branded = 'branded',
  Rebranded = 'rebranded',
  Northstar = 'northstar',
  Wills = 'wills',
}

interface ChartRow {
  heading: string
  yesNo1: string
  yesNo2: string
}

export interface ComparisonChartYesNoModuleData {
  type: string
  layout?: ComparisonChartYesNoLayout
  heading: string
  subHeading?: string
  displaySubHeading?: boolean
  title1: string
  title2: string
  ethosLogo?: string
  groups: Array<ChartRow>
  ctaLabel?: string
  displayCta?: boolean
  ctaStyle?: string
  clickthroughUrl?: string
  disclaimer?: string
  centerHeaderText?: boolean
  experimentKey?: string
}

export const ComparisonChartYesNo = ({
  moduleData,
}: {
  moduleData: ComparisonChartYesNoModuleData
}) => {
  const {
    layout,
    displaySubHeading,
    displayCta,
    ctaLabel,
    heading,
    subHeading,
    clickthroughUrl = '',
    disclaimer,
    centerHeaderText,
  } = moduleData

  const { handleNavigateToApp } = useNavigateToApp()

  const { isTreatment } = useTrustageLandingPage()

  const handleGoToMainApp = () => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        module: MODULES.COMPARISON_CHART_YES_NO,
        ctaLabel,
        clickthroughUrl,
      },
      callback: null,
    })
    handleNavigateToApp({}, false, false, false, clickthroughUrl)
  }

  const branded = layout === ComparisonChartYesNoLayout.Branded

  const rebranded = layout === ComparisonChartYesNoLayout.Rebranded

  const northstar = layout === ComparisonChartYesNoLayout.Northstar

  const CtaElement = rebranded
    ? Button.Medium.Cypress
    : northstar
    ? Button.Medium.Salamander
    : Button.Medium.Black

  const title = northstar ? (
    <div className={northstarStyles.northstarTitle}>
      <Markdown
        input={heading}
        allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
      />
    </div>
  ) : (
    <TitleLarge.Serif.Book500>
      <Markdown
        input={heading}
        allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
      />
    </TitleLarge.Serif.Book500>
  )

  const cta = (
    <div className={styles.cta} onClick={handleGoToMainApp}>
      <div className={styles.blockCtaPhoneOnly}>
        <CtaElement fullWidth>
          {isTreatment ? 'Get your rates' : ctaLabel}
        </CtaElement>
      </div>
      <div className={styles.blockCtaTabletAndUp}>
        <CtaElement> {isTreatment ? 'Get your rates' : ctaLabel}</CtaElement>
      </div>
    </div>
  )

  const stylesBind = classNames.bind(styles)
  const wrapperClasses = stylesBind('wrapper', {
    rebranded: rebranded,
    northstar: northstar,
  })

  return (
    <div className={wrapperClasses}>
      <div className={styles.intro}>
        {heading.length > 0 || displaySubHeading ? (
          <div
            className={
              centerHeaderText ? styles.centerHeadings : styles.headings
            }
          >
            {title}
            {northstar && (
              <div className={styles.northstarSpacer} aria-hidden="true" />
            )}
            <Spacer.H16 />
            {displaySubHeading && subHeading && (
              <Body.Regular400
                color={northstar ? COLORS.GRAY_PRIMARY : COLORS.GRAY_SECONDARY}
              >
                <Markdown
                  input={subHeading}
                  allowedMarkdownTypes={
                    MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY
                  }
                />
              </Body.Regular400>
            )}
            {displaySubHeading && <Spacer.H24 />}
          </div>
        ) : (
          <Spacer.H32 />
        )}
        <div className={styles.topCta}>{displayCta && cta}</div>
      </div>
      <div className={styles.chartContainer}>
        {branded || rebranded || northstar ? (
          <Branded moduleData={moduleData} />
        ) : (
          <Original moduleData={moduleData} />
        )}
        {disclaimer && (
          <>
            <Spacer.H8 />
            <Footnote.Regular400 color={COLORS.GRAY_SECONDARY}>
              {disclaimer}
            </Footnote.Regular400>
          </>
        )}
        <div className={styles.bottomCta}>
          {rebranded && <Spacer.H48 />}
          {displayCta && (rebranded || northstar) && cta}
        </div>
      </div>
    </div>
  )
}

export default ComparisonChartYesNo
